import * as React from "react";
import { Link } from "gatsby";
import Layout from "../content/Layout";
import SEO from "../content/Seo";

// markup
const NotFoundPage = () => {
  return (
    <Layout>
      <SEO title="Page Not Found" image= "../assets/Sun.png" />

      <main
        className="min-h-screen bg-cover bg-top sm:bg-top pt-36"
        style={{
          backgroundImage:
            'url("https://images.unsplash.com/photo-1545972154-9bb223aac798?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=3050&q=80&exp=8&con=-15&sat=-75")',
        }}
      >
        <div className="max-w-7xl mx-auto px-4 py-16 text-center sm:px-6 sm:py-24 lg:px-8 lg:py-48">
          <p className="text-sm font-semibold text-black text-opacity-50 uppercase tracking-wide">
            404 error
          </p>
          <h1 className="mt-2 text-4xl font-extrabold text-white tracking-tight sm:text-5xl">
            Uh oh! I think you’re lost.
          </h1>
          <p className="mt-2 text-lg font-medium text-black text-opacity-50">
            It looks like the page you’re looking for doesn't exist.
          </p>
          <div className="mt-6">
            <Link
              to="/"
              className="ilex items-center justify-center px-4 py-3 border-2 border-white rounded-full text-ec-sea bg-white hover:bg-transparent hover:text-white transition ease-in-out text-lg font-heading  sm:px-8"
            >
              Go back home
            </Link>
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default NotFoundPage;
